import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  // const footerCountry = [
  //   {
  //     image: "/images/dubai.png",
  //     country: "Dubai, UAE",
  //     address: "Office 43-44, Al Fahidi, Bur Dubai, Dubai, UAE",
  //   },
  //   {
  //     image: "/images/paris.png",
  //     country: "Paris, France",
  //     address: "66 Avenue des Champs- Elysées 75008",
  //   },
  //   {
  //     image: "/images/edenkoben.png",
  //     country: "Edenkoben, Germany",
  //     address: "Nonnenstraße 26 67480 Edenkoben Rheinland-Pfalz",
  //   },
  //   {
  //     image: "/images/ruislip.png",
  //     country: "Ruislip, England",
  //     address: "1 Kelvedon Court, Long Drive, Ruislip, England, HA4 0HJ",
  //   },
  // ];

  // const socials = [
  
  //   { image: "/images/twitter.png", text: "Twitter", link: 'https://x.com/skyventurelabs' },
  //   { image: "/images/linkedin.png", text: "Linkedin", link: 'https://www.linkedin.com/company/skyventurelabs/' },
  // ];

  return (
    <div className="bg-black px-6 md:px-10 pb-16">
      <div className="grid gap-y-10 md:grid-cols-2 p-10">
        <div className="flex flex-col gap-8 ">
          <div className="flex flex-col ">
            {/* <ImageComp image="/images/8.png" styles =" lg:w-[527px] object-contain "/> */}

            <p className="text-sm md:text-lg font-normal text-white ml-6">
              Sky Venture Labs spearheads the Web3 revolution, driving innovation and forging strategic partnerships to mold the future of technology. Join us as we build our legacy in the Web3 realm.
            </p>
          </div>
          {/* <div className="flex flex-col gap-6">
            {footerCountry?.map((item, index) => (
              <div className="flex gap-4 items-center" key={index}>
                <ImageComp image={item?.image} width={28} height={38} />
                <div className="flex flex-col">
                  <p className="text-xs text-[#C1C1C1] font-medium">
                    {item?.country}
                  </p>
                  <p className="text-xs text-[#C1C1C1]">{item?.address}</p>
                </div>
              </div>
            ))}
          </div> */}
        </div>

        <div className="flex flex-col justify-center gap-y-16 items-center ">
          {/* <div className="flex flex-col items-center md:items-start">
            <p className="uppercase text-white col-span-2 text-app-32 md:text-6xl 2xl:text-app-80 font-roboto font-extrabold mb-6">
              get a <span className="text-app-purple">quote</span>
            </p>
            <p className="font-roboto text-[#E1E1E1] font-light text-sm md:text-base 2xl:text-app-22 mb-6">
              Book your free consultation with our expert today.
            </p>
            <button className="px-4 md:px-8 2xl:px-16 py-[13px] md:py-4 2xl:py-6 text-white bg-app-purple font-normal md:font-semibold text-base 2xl:text-app-25 rounded-[42px]">
              Get a Quote
            </button>
          </div> */}
          <div className="flex items-center gap-x-6">

            {/* {socials.map((item, index) => (
              <Link to={item?.link} target="blank" className="flex items-center gap-2.5">
                <ImageComp
                  image={item?.image}
                  styles="h-6 md:h-[36px] w-6 md:w-[38px]"
                />
                <p className="text-sm md:text-lg 2xl:text-xl text-white">
                  {item.text}
                </p>
              </Link>
            ))} */}
          </div>
          <div className="flex justify-between gap-x-6">

            <p className="text-app-15 text-[#C1C1C1]">
              <Link to="/terms-and-conditions">Terms & conditions</Link>
            </p>
            <p className="text-app-15 text-[#C1C1C1]">
              <Link to="/privacy-and-policy">Privacy Policy</Link>
            </p>
          </div>
        </div>
      </div>
      <span className="h-[0.1px] inline-block bg-white w-full mt-16"></span>
    </div>
    // </div>
  );
};

export default Footer;
